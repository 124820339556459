@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500;600;700');


.navbar_link::before{
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust the position of the line as needed */
  left: 0;
  width: 0;
  height: 2px; /* Adjust the line thickness as needed */
  background-color: rgb(202 138 4);
  border-radius: 20px;
  transition: all 0.3s; /* Add a smooth transition for the line */
}
.navbar_link:hover::before{
  width: 100%;
}
.mobile-menu {
    overflow: hidden;
    transition: all 1s ease-in-out; 
  }
  
  footer{
    background: rgb(61,61,62);
    background: linear-gradient(90deg, rgba(61,61,62,1) 17%, rgba(82,82,88,1) 51%, rgba(116,116,140,1) 100%);
  }
  .navbar_scroll,.mobile-menu{
    background: rgb(116,116,140);
    background: linear-gradient(90deg, rgba(116,116,140,1) 17%, rgba(82,82,88,1) 51%, rgba(61,61,62,1) 100%);
  }
  /* Add styles to expand the mobile menu when it's open */
  .mobile-menu.open {
    /* max-height: 100vh; */
    max-height: fit-content;
  }

  .slide-1{
    background-image: url("../public/images/nature.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .slide-2{
    background-image: url("../public/images/slide-2.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .slide-3{
    background-image: url("../public/images/slide-3.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .test{
    text-shadow: 1px 1px 1px #000;
  }
 
  .different_font{
    font-family: 'Caveat', cursive;
    font-weight: 700;
  }
  .home-product{
    background-image: url("../public/images/madeni_Yag.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   padding: 5rem 0;
   background-color: #fff;
   background-blend-mode: luminosity;
  }
  .navbar-bg .navbar_with_bg{
    background: rgb(116,116,140) !important;
    background: linear-gradient(90deg, rgba(116,116,140,1) 17%, rgba(82,82,88,1) 51%, rgba(61,61,62,1) 100%) !important;
  }
  .dropdown_menu{
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
background: rgb(116,116,140);
    background: linear-gradient(90deg, rgba(116,116,140,1) 17%, rgba(82,82,88,1) 51%, rgba(61,61,62,1) 100%); 
   }
   .no_bg{
    background-color: transparent !important;
   }
   .instagram{
    background: linear-gradient(to right, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);

   }
   